var ccApiKey = '4696cff95b7162d1476ede1adb7711deec3e1984';
var ccProduct = 'COMMUNITY';

// Include the following lines to define the gtag() function when
// calling this code prior to your gtag.js or Tag Manager snippet
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

// Call the default command before gtag.js or Tag Manager runs to
// adjust how the tags operate when they run. Modify the defaults
// per your business requirements and prior consent granted/denied, e.g.:
gtag('consent', 'default', {
  'ad_storage': 'denied',
  'analytics_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied'
});

var config = {
  apiKey: ccApiKey,
  product: ccProduct,
  mode: "GDPR",
  text: {
    intro: "We use cookies to enable certain functionality, understand how our website is used and for targeted advertising purposes.  These cookies may be read by third parties and other websites you visit.  We also share information about your use of our website with our analytics partners.",
    acceptRecommended: 'Accept all cookies',
    rejectSettings: 'Reject all cookies'

  },
  closeStyle: 'button',
  rejectButton: true,
  acceptBehaviour: "recommended",
  statement: {
    description: "For more information, please see our ",
    name: "cookie policy.",
    url: "/cookie-policy",
    updated: "29/07/2020",
  },
  necessaryCookies: ['CRAFT_CSRF_TOKEN', 'CraftSessionId', '_hjid', 'visid_incap'],
  optionalCookies: [
    {
      name : 'analytics',
      label: 'Analytical Cookies',
      description: 'Analytical cookies help us to improve our website by collecting and reporting information on its usage.',
      cookies: ['_ga', '_ga*', '_gid', '_gat', '_gat_*', '_gali', '__utma', '__utmt', '__utmb', '__utmc', '__utmz', '__utmv', '_hjIncludedInSessionSample', '_hjSessionUser*', '_hjAbsoluteSessionInProgress','_hjSession*', '_hjFirstSeen'],
      onAccept: function(){
        gtag('consent', 'update', {'analytics_storage': 'granted'});
      },
      onRevoke: function(){
        gtag('consent', 'update', {'analytics_storage': 'denied'});
      },
      recommendedState: true,
      lawfulBasis: "consent",
    }, 
  ],
  layout:'slideout',
  position: "right",
  theme: "light",
  "iabCMP": false,
  rejectButton: false,
  notifyDismissButton: false,
};

CookieControl.load( config );